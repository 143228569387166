import React from 'react';
import styled from 'styled-components';
import StarRate from 'components/Core/StarRate';
import WebsiteImage from 'components/WebsiteImage';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import { reduceContentValues } from 'utils/text';

const SocialProofSection = ({ entries }) => {
  const title = getEntry('review-title', entries);
  const description = getEntry('review-description', entries);
  const logoImages = getEntry('logo-images', entries);

  return (
    <SectionRated>
      <div className="social-proof-section--container">
        {title?.content && (
          <h2 className="social-proof-section--title font-h1">
            {reduceContentValues(title?.content)}
          </h2>
        )}
        <StarRate
          value={4.5}
          starRendererProps={{
            colorActive: '#0B1A21',
            colorInactive: '#C8D4DA',
          }}
        />
        <h2 className="social-proof-section--score">4.5 out of 5</h2>
        {description?.content && (
          <p className="social-proof-section--score-description font-md">
            {reduceContentValues(description?.content)}
          </p>
        )}
        {logoImages?.components?.length > 0 && (
          <div className="social-proof-section--logo-container">
            {logoImages.components?.map((logo) => {
              const isWebsiteImage = logo?.__typename === 'ContentfulImage';

              return (
                <div className="social-proof-section--logo" key={logo.id || logo.contentful_id}>
                  <WebsiteImage
                    {...(isWebsiteImage ? { ...logo } : { image: { logo } })}
                    maxWidth={logo?.imageWidthMobile || logo?.imageWidthDesktop || '100%'}
                    width="100%"
                    height="auto"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </SectionRated>
  );
};

export default SocialProofSection;

const SectionRated = styled.div`
  background: var(--white);

  .social-proof-section--container {
    max-width: 745px;
    text-align: center;
    margin: 0 auto;
    padding: 114px 20px 100px;

    .social-proof-section--title {
      text-align: center;
      color: var(--darkest);
      margin: 0 auto 20px;
    }

    .social-proof-section--score {
      text-align: center;
      color: var(--darkest);
      margin-top: 26px;
    }

    .social-proof-section--score-description {
      text-align: center;
      color: var(--skyline-6);
      max-width: 399px;
      margin: 31px auto 0;
    }

    .social-proof-section--logo-container {
      display: flex;
      justify-content: center;
      margin-top: 62px;

      .social-proof-section--logo {
        margin: auto 0;
        padding: 0 58px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 991px) {
    .social-proof-section--container {
      .social-proof-section--score {
        margin-top: 30px;
      }

      .social-proof-section--score-description {
        margin: 20px auto 0;
      }

      .social-proof-section--logo-container {
        margin-top: 38px;

        .social-proof-section--logo {
          padding: 0 31px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .social-proof-section--container {
      padding: 60px 20px;

      .social-proof-section--score {
        margin-top: 24px;
      }

      .social-proof-section--score-description {
        margin: 14px auto 0;
      }

      .social-proof-section--logo-container {
        margin-top: 30px;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .social-proof-section--logo {
          padding: 7px 0px;
          width: 40%;

          img {
            max-width: 100% !important;
          }
        }
      }
    }
  }
`;
